export const footerLinks = [
	{ title: 'Доставка и оплата', href: '/delivery' },
	{ title: 'Новости', href: '/news' },
	{ title: 'Акции', href: '/special-offers' },
	{ title: 'Адреса магазинов', href: '/shops' },
	{ title: 'Наши контакты', href: '/contacts' },
];

export const marketplaceFeatures = [
	{
		icon: 'delivery-fast',
		text: 'Бесплатная доставка за 2 часа по Минску',
	},
	{
		icon: 'delivery',
		text: 'Быстрая доставка по Беларуси и России',
	},
	{
		icon: 'products',
		text: 'Количество товаров без ограничений',
	},
];

export const paymentImages = [
	{
		src: '/images/pay_system/visa.svg',
		alt: 'visa',
	},
	{
		src: '/images/pay_system/verified_by_visa.svg',
		alt: 'verified by visa',
	},
	{
		src: '/images/pay_system/mastercard.svg',
		alt: 'mastercard',
	},
	{
		src: '/images/pay_system/mastercard_securedcode.svg',
		alt: 'mastercard secured code',
	},
	{
		src: '/images/pay_system/belcard.svg',
		alt: 'belcard',
	},
	{
		src: '/images/pay_system/belcard_parol.svg',
		alt: 'belcard parol',
	},
	{
		src: '/images/pay_system/pay.svg',
		alt: 'pay',
	},
	{
		src: '/images/pay_system/apple_pay.svg',
		alt: 'apple pay',
	},
	{
		src: '/images/pay_system/google_pay.svg',
		alt: 'google pay',
	},
	{
		src: '/images/pay_system/bepaid.svg',
		alt: 'bepaid',
	},
	// {
	// 	src: '/images/pay_system/mir.webp',
	// 	alt: 'mir',
	// },
];

export const profileLinks = [
	{
		title: 'Заказы',
		href: '/orders',
	},
	{
		title: 'Мои данные',
		href: '/profile',
	},
	{
		title: 'Дисконтная карта',
		href: '/discount-card',
	},
];

export const regExp = {
	phone: /^\+375\s\((29|25|44|33|17)\)\s\d{3}(-\d{2}){2}$/,
	email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
	// eslint-disable-next-line no-useless-escape
	date: /^(((0[1-9]|[12]\d|3[01])\-(0[13578]|1[02])\-((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\-(0[13456789]|1[012])\-((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\-02\-((19|[2-9]\d)\d{2}))|(29\-02\-((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
};

export const socialList = [
	{ href: 'https://www.instagram.com/fh.by/', icon: 'insta', label: 'Наш Instagram' },
	// { href: '', icon: 'vk', label: 'Наша VK группа' },
	{ href: 'https://www.facebook.com/fh.belarus', icon: 'fb', label: 'Наш Facebook' },
	{ href: 'https://www.pinterest.com/fh_by/', icon: 'pinterest', label: 'Наш Pinterest' },
];

export const messengersList = [
	{ href: 'viber://pa?chatURI=fh_by_bot', icon: 'viber', label: 'Наш Viber' },
	{ href: 'https://t.me/fh_by_bot', icon: 'telegram', label: 'Наш Telegram' },
	{ href: 'https://wa.me/375296332633', icon: 'wa', label: 'Наш WhatsApp' },
];

export const messengersQualityList = [
	{ href: 'viber://chat?number=%2B375445849765', icon: 'viber', label: 'Наш Viber' },
	{ href: 'https://t.me/+375445849765', icon: 'telegram', label: 'Наш Telegram' },
	{ href: 'https://wa.me/375445849765', icon: 'wa', label: 'Наш WhatsApp' },
];

export const getEmoji = name => {
	const list = {
		checkMark: '2714',
		deliveryTruck: '1F69A',
	};

	const unifiedValue = list[name];
	if (!unifiedValue) return '';

	let emoji = `0x${unifiedValue}`;
	emoji = String.fromCodePoint(emoji);

	return emoji;
};

export const countries = {
	am: {
		name: 'Армения',
		code: '+374',
		mask: '+374 (99) 999-999',
		placeholder: '+374 (__) ___-___',
		icon: '',
	},
	by: {
		name: 'Беларусь',
		code: '+375',
		mask: '+375 (99) 999-99-99',
		placeholder: '+375 (__) ___-__-__',
		icon: '',
	},
	il: {
		name: 'Израиль',
		code: '+972',
		mask: '+\\9\\72 (99) 999-99-99',
		placeholder: '+972 (__) ___-__-__',
		icon: '',
	},
	kz: {
		name: 'Казахстан',
		code: '+7',
		mask: '+7 (999) 999-99-99',
		placeholder: '+7 (___) ___-__-__',
		icon: '',
	},
	kg: {
		name: 'Кыргызстан',
		code: '+996',
		mask: '+\\9\\96 (999) 999-999',
		placeholder: '+996 (___) ___-___',
		icon: '',
	},
	ae: {
		name: 'ОАЭ',
		code: '+971',
		mask: '+\\9\\71 (99) 999-99-99',
		placeholder: '+971 (__) ___-__-__',
		icon: '',
	},
	ru: {
		name: 'Россия',
		code: '+7',
		mask: '+7 (999) 999-99-99',
		placeholder: '+7 (___) ___-__-__',
		icon: '',
	},
	us: {
		name: 'США',
		code: '+1',
		mask: '+1 (999) 999-99-99',
		placeholder: '+1 (___) ___-__-__',
		icon: '',
	},
	tr: {
		name: 'Турция',
		code: '+90',
		mask: '+\\9\\0 (999) 999-99-99',
		placeholder: '+90 (___) ___-__-__',
		icon: '',
	},
};

export const getMask = phone => {
	if (phone.indexOf('375') === 0) {
		return countries['by'].mask;
	}
	if (phone.indexOf('374') === 0) {
		return countries['am'].mask;
	}
	if (phone.indexOf('996') === 0) {
		return countries['kg'].mask;
	}
	if (phone.indexOf('972') === 0) {
		return countries['il'].mask;
	}
	if (phone.indexOf('971') === 0) {
		return countries['ae'].mask;
	}
	if (phone.indexOf('90') === 0) {
		return countries['tr'].mask;
	}
	if (phone.indexOf('1') === 0) {
		return countries['us'].mask;
	}

	return countries['ru'].mask;
};
